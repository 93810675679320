<template>
  <div>
    <!-- <user-list-add-new :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive" :role-options="roleOptions"
        :plan-options="planOptions" @refetch-data="refetchData" />
  
      <users-list-filters :role-filter.sync="roleFilter" :plan-filter.sync="planFilter" :status-filter.sync="statusFilter"
        :role-options="roleOptions" :plan-options="planOptions" :status-options="statusOptions" /> -->

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row class="d-flex justify-content-between w-100">
          <!-- Per Page -->
          <b-col md="6" class="d-flex mt-1">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="per_page"
              class="per-page-selector d-inline-block mx-50"
              :options="['5', '10', '20', '50', '100']"
              @input="changePagination()"
            />
            <!-- Search -->
          </b-col>
          <b-col md="6" class="d-flex mt-1 ml-100">
            <div class="w-100 d-flex justify-content-end">
              <div v-if="searchTerm.type == 'dropdown'">
                <v-select
                  style="width: 200px;"
                  placeholder="Search..."
                  :options="searchTerm.storename ? getOptions(searchTerm.storename):searchTerm.options"
                  :label="searchTerm.displayName"
                  class="d-inline-block mr-1"
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div v-else>
                <b-form-input
                  class="d-inline-block mr-1"
                  placeholder="Search..."
                  v-model="search"
                  @input="handleSearch()"
                />
              </div>
              <div class="ml-1">
                <v-select
                  style="width: 150px !important"
                  :clearable="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :searchable="false"
                  v-model="searchTerm"
                  placeholder="Select"
                  class="per-page-selector d-inline-block mx-50"
                  label="name"
                  tbody-tr-class="custom-table-row"
                  :options="searchTermOptions"
                  @input="handleSearchType()"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :busy="isLoading"
        ref="refUserListTable"
        class="position-relative"
        :items="tabledata"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        @sort-changed="onSortChanged"

      >
        <template #table-busy>
          <div class="text-center text-secondary my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template #cell(id)="data">
          <span class="text-primary font-weight-bold" style="font-size: 15px">
            {{ data.index + 1 }}
          </span>
        </template>
        <!-- <template #thead-top="{ fields }">
          <tr>
            <th v-for="(field, index) in fields" :key="index">
              <template
                v-if="
                  field.key == 'id' ||
                  field.key == 'Action' ||
                  field.key == 'progress'
                "
              >
                &nbsp;
              </template>
              <template v-else-if="field.key == 'suspendDate'">
                <div class="d-flex align-items-center">
                  <flat-pickr
                    v-model="columnFilters[field.key]"
                    :config="{ mode: 'range', dateFormat: 'd-m-Y' }"
                    @input="filterTable()"
                    class="form-control p-0 m-0 flat-picker bg-transparent border-0 shadow-none"
                    placeholder="DD-MM-YYYY"
                  />
                </div>
              </template>
              <template v-else-if="field.type == 'dropdown'">
                <v-select
                  :options="getOptions('goal')"
                  :label="field.displayName"
                  v-model="columnFilters[field.key]"
                  multiple
                  @input="filterTable()"
                  style="width: 150px"
                />
              </template>
              <template v-else>
                <b-form-input
                  v-model="columnFilters[field.key]"
                  @input="filterTable()"
                  placeholder="Search..."
                />
              </template>
            </th>
          </tr>
        </template> -->

        <template #cell(f_name)="data">
          <div class="font-weight-bolder d-flex align-items-center">
            <div>
              <span class="mr-1">
                <b-avatar
                  size="32"
                  :src="data.item.avatar"
                  :text="data.item.f_name.substring(0, 1)"
                />
              </span>
              {{ data.item.f_name }}
            </div>
          </div>
        </template>
        <template #cell(suspendDate)="">
          <div class="d-flex w-100 align-items-center">
            <div>
              <p class="m-0 text-danger font-weight-bolder lableTitle">
                {{ formatDate(suspendDate) }}
              </p>
            </div>
          </div>
        </template>
        <template #cell(goal)="data">
          <div class="d-flex w-100 align-items-center">
            <p class="lableTitle">
              {{
                data.item &&
                data.item.customer_goal && data.item.customer_goal.goal
                  ? data.item.customer_goal.goal.name
                  : ""
              }}
            </p>
          </div>
        </template>
        <template #cell(phone)="data">
          <div class="d-flex w-100 align-items-center">
            <p class="lableTitle">{{ data.item.phone }}</p>
          </div>
        </template>
        <template #cell(progress)="data">
          <div class="d-flex align-items-center" style="width:200px">
            <span class="font-weight-bold" style="font-size: 15px"
              >{{ data.item.progress ? data.item.progress.toFixed(2):0 }}%</span
            >
            <b-progress
              :value="data.item.progress"
              class="w-100 ml-1"
              :variant="data.item.progress < 50 ? 'danger' : 'success'"
            ></b-progress>
          </div>
        </template>
        <template #cell(Action)="data">
          <div class="d-flex">
            <b-badge
              @click="handleUnsuspend(data.item.id)"
              class="mr-1 font-weigth-bolder cursor-pointer"
              style="font-size: 15px"
              variant="light-danger"
            >
              Unsuspend
            </b-badge>
            <feather-icon
              icon="EyeIcon"
              size="22"
              @click="openProfile(data.item)"
              class="cursor-pointer mr-1"
            />
          </div>
        </template>
      </b-table>
      <div class="w-100 d-flex justify-content-between p-1">
        <!-- Pagination -->
        <div>
          <span class="text-nowrap">
            Showing {{ paginationStart }} to {{ paginationEnd }} of
            {{ paginationTotal }}</span
          >
        </div>
        <b-pagination
          :total-rows="paginationTotal"
          :per-page="per_page"
          v-model="paginationValue"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
          @input="changePagination()"
        >
          <!-- <div>
            <button
              @click="goToPage(paginationValue - 1)"
              :disabled="paginationValue === 1"
            >
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </button>
            <button
              @click="goToPage(paginationValue + 1)"
              :disabled="paginationValue * per_page >= paginationTotal"
            >
              <feather-icon icon="ChevronRightIcon" size="18" />
            </button>
          </div> -->
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card>
  </div>
</template>
  
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BProgress,
  BFormGroup,
  BFormSelect,
  BForm,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import axios from "axios";
import moment from "moment";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import _ from "lodash";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  components: {
    BFormSelect,
    BInputGroup,
    BCard,
    BRow,
    moment,
    required,
    BInputGroupAppend,
    BCol,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BFormGroup,
    required,
    BDropdown,
    BDropdownItem,
    BPagination,
    BProgress,
    BSpinner,
    vSelect,
    BForm,
    flatPickr,
  },
  data() {
    return {
      tableColumns: [
        {
          key: "id",
          label: "NO",
          sortable: true,
        },
        { key: "f_name", label: "Client", sortable: true },
        { key: "suspendDate", label: "Date", type: "date" },
        {
          key: "goal",
          label: "GOAL",
          type: "dropdown",
          displayName: "name",
        },
        // { key: 'total', sortable: true, formatter: val => `$${val}` },
        // { key: 'balance', sortable: true },
        { key: "phone", label: "Contact", sortable: true },
        { key: "progress", label: "Progress",},
        { key: "suspend_type", label: "Type",},
        { key: "Action", label: "Action" },
      ],
      columnFilters: {},
      searchTerm: { name: "Client", value: "customer" },
      newTerm: { name: "Trainer", value: "trainer" },
      newSearch: "",
      search: "",
      fullname: "",
      height: "",
      suspendDate: [],
      tabledata: [],
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      weight: "",
      isLoading: false,
      email: "",
      contact: "",
      pincode: "",
      totalTairner: "",
      language: "English",
      password: "",
      confirm_password: "",
      value: 60,
      isSortDirDesc: true,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      passwordMismatch: false,
      AddNewUser: false,
      gender: "",
      goal: "",
      loader: true,
      AllTrainer: [],
      goalOption: [],
      passwordIcon: "password",
      confirmpasswordIcon: "password",
      genderOption: [],
      country: "",
      contryOption: [],
      plans: "",
      plansOption: [],
      ifEdit: false,
      id: 0,
      UserID: "",
      assignTrainer: false,
      paginationStart: 0,
      paginationEnd: 0,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      params: {},
      searchTermOptions: [
        { name: "Client", value: "customer"  },
        { name: "Goal", value: "goal", type: "dropdown", displayName: "name",storename:'goal'},
        { name: "Type", value: "suspend_type", type: "dropdown" ,options:['Permanent','Temporary'] },
      ],
      searchOptions:[]
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    ConfirmIcon() {
      return this.confirmpasswordIcon === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    filteredItems() {
      // Custom method to filter items based on columnFilters
      return this.items.filter((item) => {
        return this.fields.every((field) => {
          const filterValue = this.columnFilters[field.key];
          if (filterValue) {
            return String(item[field.key])
              .toLowerCase()
              .includes(filterValue.toLowerCase());
          }
          return true;
        });
      });
    },
  },
  watch: {
    password() {
      this.validatePasswordMatch();
    },
    confirm_password() {
      this.validatePasswordMatch();
    },
  },
  mounted() {
    this.getClientData();
    this.getAllTrainer();
  },
  methods: {
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      // console.log(this.sortBy)
      // console.log(this.orderBy)
      this.getClientData();
    },
    handleSearchType(){
      this.search=''
      this.getClientData()
    },
    filterTable: _.debounce(function () {
      this.params = {};
      for (let key in this.columnFilters) {
        if (
          this.columnFilters[key] &&
          typeof this.columnFilters[key] == "object"
        ) {
          this.params[key] = this.columnFilters[key]
            .map((inner) => inner.id)
            .join(",");
        } else {
          this.params[key] = this.columnFilters[key];
        }
      }
      this.params.page = this.paginationValue;
      this.getClientData();
    }, 1000),

    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    validatePasswordMatch() {
      if (this.password !== this.confirm_password) {
        this.passwordMismatch = true;
      } else {
        this.passwordMismatch = false;
      }
    },
    resetModal() {
      this.AddNewUser = false;
      this.ifEdit = false;
      this.fullname = "";
      this.gender = "";
      this.goal = "";
      this.height = "";
      this.weight = "";
      this.email = "";
      this.contact = "";
      this.language = "";
      this.country = "";
      this.plans = "";
      this.password = "";
      this.confirm_password = "";
      this.id = "";
      this.UserID = "";
    },
    changePagination() {
      this.getClientData();
    },
    changeSearch() {
      if (this.search) {
        this.getClientData();
      }
    },
    toggleConfirmPassword() {
      this.confirmpasswordIcon =
        this.confirmpasswordIcon === "password" ? "text" : "password";
    },
    togglePassword() {
      this.passwordIcon =
        this.passwordIcon === "password" ? "text" : "password";
    },
    async handleEditForm(item) {
      this.AddNewUser = true;
      this.ifEdit = true;
      await axios({
        method: "GET",
        url: `${this.baseApi}customer/show/${item.id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((res) => {
          let data = res.data.data.user;
          this.AddNewUser = true;
          this.ifEdit = true;
          this.fullname = data.f_name;
          this.gender = data.gender;
          this.goal_id = data.goal_id;
          this.height = data.height;
          this.weight = data.current_weight;
          this.email = data.email;
          this.contact = data.phone;
          this.language = "English";
          this.country = data.country;
          this.plans = data.plans;
          this.password = "";
          this.confirm_password = "";
          this.id = data.id;
          this.UserID = data.username;
        })
        .catch((error) => {
          console.error("Error update user:", error);
        });
    },
    async getAllTrainer() {
      this.isDataLoading = true;
      let newParam = `?page=${this.paginationValue}&per_page=${this.per_page}${
        this.newSearch ? "&search=" + this.newSearch : ""
      }${this.newSearch && this.newTerm ? "&type=" + this.newTerm.value : ""}`;
      await axios({
        method: "GET",
        url: `${this.baseApi}trainer/all${newParam}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
      })
        .then((json) => {
          this.AllTrainer = json?.data.data.data;
          this.totalTairner = json?.data.data.total;
          this.isDataLoading = false;
        })
        .catch((error) => console.log(error, "error"));
    },
    async getClientData() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}dashboard/suspend-client`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.tabledata = json?.data.data.data;
          this.suspendDate =
            this.tabledata && this.tabledata.length > 0
              ? this.tabledata[0].suspend_date
              : "";
          this.paginationTotal = json?.data.data.total;
          this.paginationStart = json?.data.data.from;
          this.paginationEnd = json?.data.data.to;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          console.log(error, "error");
        });
    },

    handleSearch: _.debounce(function () {
      this.getClientData();
      // this.getAllTrainer();
    }, 1000),
    handleTrainerSearch: _.debounce(function () {
      this.getAllTrainer();
    }, 1000),

    async handleUnsuspend(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, Unsuspend it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              // Update the API endpoint and payload for unsuspending
              const unsuspendData = {
                user_id: id,
              };

              await axios({
                method: "post", // Change the HTTP method to POST
                url: `${this.baseApi}dashboard/unsuspend-user`, // Update the API endpoint
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.accessToken}`,
                },
                data: unsuspendData, // Pass the payload directly
              });

              this.$swal({
                title: "Unsuspended",
                text: "Client unsuspended successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.getClientData();
            } catch (error) {
              console.error("Error unsuspending user:", error);
            }
          }
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {});
    },
    openProfile(item) {
      const userId = item.id;
      this.$router.push({
        path: `/clientmanagement/${userId}`,
        query: { type: "profile" },
      });
    },

    Cancel() {
      this.AddNewUser = false;
      this.resetModal();
    },

    goToPage(page) {
      if (
        page >= 1 &&
        page <= Math.ceil(this.paginationTotal / this.per_page)
      ) {
        this.paginationValue = page;
        this.getClientData();
      }
    },
    async handleSubmitForm(id) {
      await this.handleForm(id);
      this.getClientData();
    },
  },
};
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.lableTitle {
  font-size: 15px;
  font-weight: 500;
  font-family: "Public Sans", sans-serif;
}
</style>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  
  
  
  